@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "Gilroy-light";
  src: local("Gilroy"), url("./fonts/Gilroy-Light.otf") format("truetype");
}

@font-face {
  font-family: "mulish";
  src: local("Mulish"), url("./fonts/Mulish-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
